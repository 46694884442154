<template>
    <b-card class="blog-edit-wrapper">
      <!-- form -->
      <validation-observer ref="addCourseFormvalidate">
        <b-form class="mt-2" style="width: 100%" @submit.prevent="save">
         <b-row>
        
        
            <b-col
              cols="12"
              class="mb-2"
            >
              <h3>Our Services</h3>
              <div class="border rounded p-2">
                <b-media
                  v-for="(item, i) in form.our_services"
                  :key="i"
                  no-body
                  vertical-align="center"
                  class="flex-column flex-md-row"
                >
          
                  <b-media-aside>
                    <b-img
                      ref="refPreviewE2"
                      :src="
                        item.image ? `https://api.lmitac.com/${item.image}` : Tabimage
                      "
                      height="150"
                      width="200"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>
                  <b-media-body>
                    <b-card-text class="my-50" />
                    <b-col
                      v-if="i != 0"
                      cols="12"
                      class="mt-50"
                      style="text-align: end"
                    >
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="outline"
                        class="mr-1"
                        @click="removeService(i)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </b-col>
                    <div class="d-inline-block">
                      <b-form-group
                      
                        label-for="blog-edit-title"
                        class="mb-2"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Tab Image "
                        >
                          <input
                            ref="newThumbnailgoal"
                            type="file"
                            accept=".png, .jpg, .jpeg"
                            class="form-control"
                            @change="uploadImage($event, item)"
                          >
                          <b-form-input
                            id="alt-image"
                            v-model="item.icon"
                          />
                          <!-- <b-form-file
                            ref="refInputE2"
                            v-model="form.our_goal_image"
                            accept=".jpg, .png, .gif, .jpeg"
                            placeholder="Choose file"
                            @input="inputImageRendererTab" /> -->
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <b-form-group
                        label="Name"
                        label-for="blog-edit-title"
                        class="mb-2"
                      >
                        <b-form-input
                          id="alt-image"
                          v-model="item.name"
                        />
                      </b-form-group>
                      <b-form-group
                        label="link"
                        label-for="blog-edit-title"
                        class="mb-2"
                      >
                        <b-form-input
                          id="alt-image"
                          v-model="item.link"
                        />
                      </b-form-group>
                    </div>
                  </b-media-body>
  
                  <hr>
                </b-media>
  
                <b-row
                  class="text-end"
                  style="text-align: end"
                >
                  <b-col
                    cols="12"
                    class="mt-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mr-1"
                      @click="newService"
                    >
                      new Services
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <b-col
              cols="12"
              class="mb-2"
            >
              <h3> Statistics</h3>
              <div class="border rounded p-2">
                <b-media
                  v-for="(item, i) in form.statistics"
                  :key="i"
                  no-body
                  vertical-align="center"
                  class="flex-column flex-md-row"
                >
                  <b-media-aside>
                    <b-img
                      ref="refPreviewE2"
                      :src="
                        item.image ? `https://api.lmitac.com/${item.image}` : Tabimage
                      "
                      height="150"
                      width="200"
                      class="rounded mr-2 mb-1 mb-md-0"
                    />
                  </b-media-aside>
                  <b-media-body>
                    <b-card-text class="my-50" />
                    <b-col
                      v-if="i != 0"
                      cols="12"
                      class="mt-50"
                      style="text-align: end"
                    >
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="outline"
                        class="mr-1"
                        @click="removeFeedback(i)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </b-col>
                    <div class="d-inline-block">
                      <b-form-group
                        label="header image"
                        label-for="blog-edit-title"
                        class="mb-2"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Tab Image "
                        >
                          <input
                            ref="newThumbnailgoal"
                            type="file"
                            accept=".png, .jpg, .jpeg"
                            class="form-control"
                            @change="uploadImage($event, item)"
                          >
                          <b-form-input
                            id="alt-image"
                            v-model="item.icon"
                          />
                          <!-- <b-form-file
                            ref="refInputE2"
                            v-model="form.our_goal_image"
                            accept=".jpg, .png, .gif, .jpeg"
                            placeholder="Choose file"
                            @input="inputImageRendererTab" /> -->
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <b-form-group
                        label="Name"
                        label-for="blog-edit-title"
                        class="mb-2"
                      >
                        <b-form-input
                          id="alt-image"
                          v-model="item.name"
                        />
                      </b-form-group>
                      <b-form-group
                        label="content"
                        label-for="blog-edit-title"
                        class="mb-2"
                      >
                        <b-form-input
                          id="alt-image"
                          v-model="item.content"
                        />
                      </b-form-group>
                    </div>
                  </b-media-body>
  
                  <hr>
                </b-media>
  
                <b-row
                  class="text-end"
                  style="text-align: end"
                >
                  <b-col
                    cols="12"
                    class="mt-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mr-1"
                      @click="newStatictcs"
                    >
                      new Statistics
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <!-- <b-col
              cols="12"
              class="mb-2"
            >
              <h3>Location</h3>
  
              <div class="border rounded p-2">
                <b-col cols="6">
              <b-form-group label-for="blog-content" class="mb-2">
                <validation-provider
                  #default="{ errors }"
                  name="Description"
                 >
                 <b-form-input v-model="form.location.title" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label-for="blog-content" class="mb-2">
                <validation-provider
                  #default="{ errors }"
                  name="Description"
                 >
                  <quill-editor v-model="form.location.description" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
                <b-media
                  v-for="(item, i) in form.location.positions"
                  :key="i"
                  no-body
                  vertical-align="center"
                  class="flex-column flex-md-row"
                >
                  <b-media-body>
                    <b-card-text class="my-50" />
                    <b-col
                      v-if="i != 0"
                      cols="12"
                      class="mt-50"
                      style="text-align: end"
                    >
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="outline"
                        class="mr-1"
                        @click="removePosition(i)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </b-button>
                    </b-col>
  
                    <b-form-group
                      label="Name"
                      label-for="blog-edit-title"
                      class="mb-2"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="name "
                        rules="required"
                      >
                        <b-form-input
                          id="alt-image"
                          v-model="item.name"
                        />
                  
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="company"
                      label-for="blog-edit-company"
                      class="mb-2"
                    >  <validation-provider
                        #default="{ errors }"
                        name="company "
                        rules="required"
                      >
                      <b-form-input
                        id="alt-company"
                        v-model="item.company"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="feedback"
                      label-for="blog-edit-feedback"
                      class="mb-2"
                    >
                    <validation-provider
                        #default="{ errors }"
                        name="feedback "
                        rules="required"
                      >
                      <b-form-input
                        id="alt-feedback"
                        v-model="item.feedback"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-media-body>
  
                  <hr>
                </b-media>
  
                <b-row
                  class="text-end"
                  style="text-align: end"
                >
                  <b-col
                    cols="12"
                    class="mt-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="mr-1"
                      @click="newPosition"
                    >
                      new Position
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </b-col> -->
            <b-col cols="10" class="mt-50">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click="save">
                Save Changes
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary">
                Cancel
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <!--/ form -->
    </b-card>
  </template>
  
  <script>
  import {
    BCard,
    BMedia,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BImg,
    BFormFile,
    BLink,
    BButton,
  } from 'bootstrap-vue';
  import { ValidationProvider, ValidationObserver } from 'vee-validate';
  import vSelect from 'vue-select';
  import { quillEditor } from 'vue-quill-editor';
  import { required, email } from '@validations';
  import Ripple from 'vue-ripple-directive';
  import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils';
  import { ref, reactive } from '@vue/composition-api';
  import Vue from 'vue';
  import store from '@/store';
  
  export default {
    setup() {
      // const store = useStore();
      const refInputEl = ref(null);
      const refInputE2 = ref(null);
      const refPreviewEl = ref(null);
      const refPreviewE2 = ref(null);
      const refInputE3 = ref(null);
      const refInputE4 = ref(null);
      const refPreviewE4 = ref(null);
      const refPreviewE3 = ref(null);
      const refInputE5 = ref(null);
      const refInputE6 = ref(null);
      const refPreviewE5 = ref(null);
      const refPreviewE6 = ref(null);
      const Tabimage = ref('media/svg/files/blank-image.svg');
      const categoriesList = ref([]);
      const form = ref({
  
        our_services:[{
          name: '',
          link: '',
          image: '',
        }],
        statistics:[{
          name: '',
          content: '',
          image: '',
        }],
        location:{
          title:'',
          description:'',
          positions:[{
          "title": "",
          "latitude": null,
          "longitude":null,
          "url": "",
          "color": ""
        
  
        }]}
      });
      const addCourseForm = reactive(form);
      const data = [
        'who_we_are',
        'our_goal',
        'what_we_do1',
        'what_we_do2',
        'what_we_do3',
        'what_we_do4',
      ];
      const formData = new FormData();
      formData.append('data', JSON.stringify(data));
      store.dispatch('setting/GetAboutContent').then((response) => {
        form.value=response.data
      
      });
  
      const addCourseFormvalidate = ref();
     
      const save = () => {
        const formData = new FormData();
  
 
  
        formData.append('data', JSON.stringify(data));
        formData.append('our_services', JSON.stringify(form.value.our_services));
        formData.append('statistics', JSON.stringify(form.value.statistics));
        addCourseFormvalidate.value.validate().then((success) => {
          if (success) {
            store
              .dispatch('setting/updateAboutContent', form.value)
              .then((response) => {
                Vue.swal({
                  title: 'Updated',
                  icon: 'success',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                });
              })
              .catch((error) => {
                Vue.swal({
                  title: '',
                  text: `${error.response.data.message}`,
                  icon: 'error',
                  confirmButtonText: 'Ok',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                });
              });
          }
        });
      };
    
   
      const newService = () => {
        console.log(form)
        form.value.our_services.push({
          name: '',
          link: '',
          image: '',
        })
      }
      const removeService = i => {
        form.value.our_services.splice(i, 1)
      }
      const newStatictcs = () => {
        form.value.statistics.push({
          name: '',
          content: '',
          image: '',
        })
      }
      const removeStatictcs = i => {
        form.value.statistics.splice(i, 1)
      }
      const newPosition = () => {
        form.value.location.positions.push({
          "title": "",
          "latitude": null,
          "longitude":null,
          "url": "",
          "color": ""
        })
      }
      const removePosition = i => {
        form.value.location.positions.splice(i, 1)
      }
      const uploadImage = (i, item) => {
        console.log(i)
        const file = i.target.files[0]
  
        const fd = new FormData()
  
        fd.append('image', file)
        fd.append('path', 'about')
        store.dispatch('setting/uploadFile', fd).then(response => {
          console.log('res', response)
          item.image = response.location
        })
      }
      return {
        uploadImage,
        newService,
        newStatictcs,
        removeStatictcs,
        newPosition,
        removeService,
        removePosition,

        refInputEl,
        refPreviewEl,
        refInputE2,
        refPreviewE2,
        refInputE3,
        refPreviewE3,
        refInputE4,
        refPreviewE4,
        refInputE5,
        refPreviewE5,
        refInputE6,
        refPreviewE6,
  
        Tabimage,
        addCourseForm,
  
        addCourseFormvalidate,
        categoriesList,
        form,
  
        save,
        required,
        email,
      };
    },
  
    components: {
      BCard,
      BMedia,
      ValidationProvider,
      ValidationObserver,
      BCardText,
      BMediaAside,
      BMediaBody,
      BFormRadio,
      BFormCheckbox,
      BFormRadioGroup,
      BForm,
      BLink,
      BImg,
      BRow,
      BCol,
      BButton,
      BFormGroup,
      BFormInput,
      BFormFile,
      vSelect,
      quillEditor,
    },
  
    directives: {
      Ripple,
    },
  };
  </script>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  @import "@core/scss/vue/libs/quill.scss";
  @import "@core/scss/vue/pages/page-blog.scss";
  </style>
  <style>
  label.title {
    font-size: 25px;
  }
  .border.rounded.p-2 {
    margin: 1px !important;
  }
  .p-2 {
    margin: 0px !important;
  }
  </style>
  